import router from '@/router/index';
import MetaService from "@/services/resources/MetaService";
import MemberService from "@/services/resources/MemberService";
import CertifyService from "@/services/resources/CertifyService";

export default {
    getMetasSite: ({
        commit,
        dispatch
    }) => {
        const serviceMeta = MetaService.build();
        return new Promise((resolve, reject) => {
            serviceMeta.search("keys[]=onboarding&keys[]=comment_review&keys[]=helper_data&keys[]=main_color&keys[]=logo&keys[]=theme_fixed_painel&keys[]=theme_fixed_painel_color&keys[]=termsOfUseActivated&keys[]=logomarca&keys[]=panel_background_image")
                .then((resp) => {
                    commit('metasSite', resp);
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getCertificateConfig: ({
        commit,
        dispatch
    }) => {
        const serviceCertificate = CertifyService.build();
        return new Promise((resolve, reject) => {
            serviceCertificate.read("check-exist")
                .then((resp) => {
                    commit('certificateConfig', resp);
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getCurrentSiteAndMember: ({
        commit,
        dispatch
    }) => {
        const serviceMember = MemberService.build();
        return new Promise((resolve, reject) => {
            serviceMember.read("/me")
                .then((resp) => {
                    //console.log('/me', resp)
                    commit('currentSite', resp.current);
                    // commit('currentMember', resp.member);
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    userRequest: async ({ commit, dispatch }) => {
        dispatch('redirectUserToHome');
    },
    redirectUserToHome: (context) => {
        router.go(0);
    }
}
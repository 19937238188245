import Vue from "vue";
import App from "./App.vue";
import i18n from "./i18n";
import BootstrapVue from "bootstrap-vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

import VeeValidate from "vee-validate";
import { Validator } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueTheMask from "vue-the-mask";
import StarRating from "vue-star-rating";
import components from "./components/index";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import Vue2Filters from "vue2-filters";
import ToggleButton from "vue-js-toggle-button";
import VueQuillEditor from "vue-quill-editor";
import Snackbar from "node-snackbar";
import ModalNovoCertificado from "@/components/ModalNovoCertificado";
import Blur from "@/components/Blur";
import Intro from "@/components/Intro";
import VueIntro from "vue-introjs";
import "intro.js/introjs.css";
import VueObserveVisibility from "vue-observe-visibility";
import vueVimeoPlayer from "vue-vimeo-player";
import { func } from "@/services/libs/convertColor.js";
import Button from "@/components/base/Button";
import Modal from "@/components/base/Modal";
import ModalUploadPanda from "@/components/ModalUploadPanda.vue";
import "./assets/scss/multiple-select.scss";
import VueYoutube from "vue-youtube";

Vue.use(VueYoutube);
Vue.component("ModalUploadPanda", ModalUploadPanda);
Vue.component("BaseButton", Button);
Vue.component("BaseModal", Modal);
Vue.prototype.$func = func;

Vue.use(vueVimeoPlayer);
Vue.use(VueObserveVisibility);
Vue.use(VueIntro);
Vue.component("blur", Blur);
Vue.component("my-component-certify", ModalNovoCertificado);
Vue.component("my-component-intro", Intro);
Vue.use(Snackbar);
Vue.use(VueQuillEditor /* { default global options } */);
Vue.use(ToggleButton);
Vue.use(Vue2Filters);
Vue.use(require("vue-moment"));
Vue.use(VueperSlides);
Vue.use(VueperSlide);
Vue.use(StarRating);
Vue.use(VueTheMask);
Vue.use(BootstrapVue);

library.add(fas, far, fab);

Vue.use(VeeValidate, { fieldsBagName: "veeFields" });
Validator.localize("pt_BR", pt_BR);
Vue.component("font-awesome-icon", FontAwesomeIcon);

export const EventBus = new Vue();

for (let componentKey in components) {
  Vue.component(componentKey, components[componentKey]);
}
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

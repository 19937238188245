import Cookies from "js-cookie";

import getters from "@/store/modules/globalSettings/getters";
import mutations from "@/store/modules/globalSettings/mutations";

const state = {
  theme: Cookies.get("themeClub") || "light",
  clubVersion: Cookies.get("clubVersion") || "v1",
};

export { state, getters, mutations };

import Cookies from 'js-cookie';

import actions from '@/store/modules/user/actions'
import getters from '@/store/modules/user/getters';
import mutations from '@/store/modules/user/mutations'

const state = {
    metasSite: (Cookies.get('metasSite')) ? JSON.parse(Cookies.get('metasSite')) : {},
    certificateConfig: (Cookies.get('certificateConfig')) ? JSON.parse(Cookies.get('certificateConfig')) : {},
    // currentMember: (Cookies.get('currentMember')) ? JSON.parse(Cookies.get('currentMember')) : {},
    currentSite: (Cookies.get('currentSite')) ? JSON.parse(Cookies.get('currentSite')) : {},
}

export {
    state,
    getters,
    actions,
    mutations,
}
